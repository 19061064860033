'use client';

import useAuth from '@/contexts/AuthProvider';
import mixpanel from 'mixpanel-browser';

import { logEvent } from 'firebase/analytics';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { MIXPANEL_TOKEN, PIXELID } from '../apis/config';
import { firebaseAnalytics } from './firebase';

const useAnalytics = () => {
	const pathname = usePathname();
	const { user, isLogged } = useAuth();

	useEffect(() => {
		// Facebook Pixel Initialization
		import('react-facebook-pixel')
			.then((x) => x.default)
			.then((ReactPixel) => {
				ReactPixel.init(PIXELID); // facebookPixelId
				ReactPixel.pageView();
			})
			.catch((err) => {
				console.log('err', err);
			});
	}, [pathname]);

	useEffect(() => {
		// Mixpanel Initialization
		mixpanel.init(MIXPANEL_TOKEN, {
			// track_pageview: 'full-url',
			debug: process.env.NODE_ENV === 'development' ? true : false
		});

		if (isLogged && user?.u_id) {
			mixpanel.identify(user?.u_id);
			mixpanel.people.set({
				$distinct_id: user?.u_id,
				$name: user?.u_name,
				$phone: user?.u_mobile
			});
			mixpanel.register({
				user_id: user?.u_id,
				user_name: user?.u_name,
				user_mobile: user?.u_mobile
			});
		} else {
			mixpanel.reset();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname, isLogged, user?.u_id]);

	const fbEvent = (eventName: string, eventParams: any) => {
		import('react-facebook-pixel')
			.then((x) => x.default)
			.then((ReactPixel) => {
				ReactPixel.init(PIXELID); // facebookPixelId
				ReactPixel.track(eventName, eventParams);
			});
	};

	const firebaseEvent = (eventName: string, eventParams: any) => {
		logEvent(firebaseAnalytics, eventName, eventParams);
	};

	const mixpanelEvent = (eventName: string, eventParams: Record<string, any>) => {
		if (mixpanel) {
			mixpanel.track(eventName, eventParams);
		} else {
			console.warn('Mixpanel is not initialized');
		}
	};

	return { fbEvent, firebaseEvent, mixpanelEvent };
};

export default useAnalytics;
